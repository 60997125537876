import { Controller } from "stimulus"
import places from 'places.js';


export default class extends Controller {

  static targets = ["address"]

  orderInitAutocomplete(event) {
    this.addressTargets.forEach(address => {
      places({ container: address })
    })
  }
}
