// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'controllers'
// import { Turbo } from '@hotwired/turbo-rails'
// Turbo.session.drive = false
// const i18n = new I18n();

// var language = "<%= I18n.locale %>";
// I18n.translations = <%== I18n::JS.filtered_translations.to_json %>;
require('@rails/ujs').start()
require('@rails/activestorage').start()
require('turbolinks').start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// To move to Stimulus later


// ---- MOBILE NAVBAR ----
const toggle = el => el.classList.toggle('d-none')

const togglers         = document.querySelectorAll('.navbar__toggle')
const hamburger        = document.querySelector('.navbar__hamburger')
const cross            = document.querySelector('.navbar__cross')
const logo             = document.querySelector('.navbar__logo')
const backgroundShadow = document.querySelector('.background--shadow')
const mobileMenu       = document.querySelector('.navbar__options')

const toggleMenu = () => {
  toggle(hamburger)
  toggle(cross)
  toggle(backgroundShadow)
  toggle(logo)
  mobileMenu.classList.toggle('navbar__options--shown')
}

if (togglers) togglers.forEach(toggler => toggler.addEventListener('click', toggleMenu))

// ------------------------

// --- STICKY NAVBAR ---

const STICKY_START = 75

const navbar       = document.querySelector('.navbar')
const navbarSticky = document.querySelector('.navbar--sticky')

const displaySticky = () => {
  if (window.pageYOffset >= STICKY_START) {
    navbar.classList.add('d-none')
    navbarSticky.classList.remove('d-none')
  } else {
    navbar.classList.remove('d-none')
    navbarSticky.classList.add('d-none')
  }
}

window.addEventListener('scroll', displaySticky)

// ----------------

