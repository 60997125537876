import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["collapsibles", "titles", "infobtn"]

  simpleToggle(event) {
    this.collapsiblesTarget.classList.add('d-none')
  }

  toggleCollapse(event) {
    const collapse = event.currentTarget

    // Close other toggles
    this._hideAllCollapsibles(collapse)

    collapse.classList
      .toggle('collapsible--active')
    collapse.nextElementSibling.classList
      .toggle('d-none')
  }

  toggleEstimateDetails(event) {
    const title = event.currentTarget
    this.titlesTarget.classList.toggle('results__card__clicked')
    this.collapsiblesTargets.forEach(toggle => {
      toggle.classList.toggle('d-none')
    })
  }

  toggleCtaCard(event) {
    this.collapsiblesTargets.forEach(toggle => {
      toggle.classList.toggle('d-none')
    })
  }

  // WORKING TOGGLE HERE
  //    toggleEstimateDetails(event) {
  //   const collapse = event.currentTarget
  //   this.collapsiblesTarget.classList.toggle('d-none')
  // }

  // Private methods

  _hideAllCollapsibles(collapse) {
    this.collapsiblesTargets.forEach(toggle => {
      // Don't do anything if the collapse is the clicked one
      if (toggle === collapse) return
      toggle.classList
        .remove('collapsible--active')
      toggle.nextElementSibling.classList
        .add('d-none')
    })
  }
}
