import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cookieModal"]

  connect() {
    if (!document.cookie.split('; ').find(row => row.startsWith('doSomethingOnlyOnce'))) {
    this.cookieModalTarget.classList.remove('d-none');
    }
  }

  hideModal(event) {
    this.cookieModalTarget.classList.add('d-none');
    document.cookie = "doSomethingOnlyOnce=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
  }
}
