import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["collapsibles"]

  toggleCollapse(event) {
    const collapse = event.currentTarget

    // Close other toggles
    this._hideAllCollapsibles(collapse)

    collapse.classList
      .toggle('collapsible--active')
    collapse.nextElementSibling.classList
      .toggle('d-none')
  }

  // Private methods

  _hideAllCollapsibles(collapse) {
    this.collapsiblesTargets.forEach(toggle => {
      // Don't do anything if the collapse is the clicked one
      if (toggle === collapse) return
      toggle.classList
        .remove('collapsible--active')
      toggle.nextElementSibling.classList
        .add('d-none')
    })
  }
}
