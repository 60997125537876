import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "formcontent", "formContainer", "thxMsg", "overlay", "requiredField"]

  displayModal(event) {
    this.modalTarget.classList.remove('d-none')
    this.overlayTarget.classList.remove('d-none')
  }

  hideModal(event) {
    this.modalTarget.classList.add('d-none')
    this.overlayTarget.classList.add('d-none')
    this.formcontentTarget.classList.remove('d-none')
    this.thxMsgTarget
        .classList
        .add('d-none')
  }

  showThankMsg(event) {
    const required = this.requiredFieldTarget.value
    // const required = this.requiredFieldTargets.forEach(field => {
    //   field.value
    // })
    console.log(required)
    // CHECK IF FORM MANDATORY FIELDS ARE FILLED
    if (required != "") {
      // HIDE FORM
      this.formcontentTarget.classList.add('d-none')
      // SHOW THANK YOU MSG
      this.thxMsgTarget
          .classList
          .remove('d-none')
    }
  }
}
